import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login'
import Home from '../views/Home'
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'login',
    component:Login
  },
  {
    path:'/home',
    component:Home,
    redirect:'/home/LsolationPersonnel',
    children:[
      {
        path:'/home/LsolationPersonnel',
        name:'LsolationPersonnel',
        component: () => import('@/views/page/LsolationPersonnel'),
      },
      {
        path:'/home/AbnormalTemperature',
        name:'AbnormalTemperature',
        component: () => import('@/views/page/AbnormalTemperature'),
      },
      {
        path:'/home/LsolationPersonnelFeedback',
        name:'LsolationPersonnelFeedback',
        component: () => import('@/views/page/LsolationPersonnelFeedback'),
      },
      {
        path:'/home/LsolationPointMedicalStaff',
        name:'LsolationPointMedicalStaff',
        component: () => import('@/views/page/LsolationPointMedicalStaff'),
      },
      {
        path:'/home/Locationofisolationpoint',
        name:'Locationofisolationpoint',
        component: () => import('@/views/page/Locationofisolationpoint'),
      },
      {
        path:'/home/ReleaseQuarantine',
        name:'ReleaseQuarantine',
        component: () => import('@/views/page/ReleaseQuarantine'),
      },
      {
        path:'/home/TemperatureMeasurement',
        name:'TemperatureMeasurement',
        component: () => import('@/views/page/TemperatureMeasurement'),
      },
      {
        path:'/home/MaterialInventory',
        name:'MaterialInventory',
        component: () => import('@/views/page/MaterialInventory'),
      },
      {
        path:'/home/AddLsolationPersonnel',
        name:'AddLsolationPersonnel',
        component: () => import('@/views/page/AddLsolationPersonnel'),
      },
      {
        path:'/home/AddLsolationPointMedicalStaff',
        name:'AddLsolationPointMedicalStaff',
        component: () => import('@/views/page/AddLsolationPointMedicalStaff'),
      },
      {
        path:'/home/UserAdmin',
        name:'UserAdmin',
        component: () => import('@/views/page/UserAdmin'),
      },
      {
        path:'/home/Equipment',
        name:'Equipment',
        component: () => import('@/views/page/Equipment'),
      },
      {
        path:'/home/RoleAdmin',
        name:'RoleAdmin',
        component: () => import('@/views/page/RoleAdmin'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    next();
  } else {
    let token = localStorage.getItem('token');
    if (!token) {
      next('/');
    } else {
      next();
    }
  }
});
export default router
