import axios from 'axios'
import { Message, Loading } from 'element-ui'
import configs from './config'
import api from './api'
import { Promise } from 'core-js'
let loading
let qs = require('qs')
let path = null
let loadingFrequency = 0 //loading次数
let unwantedLoading = [] //不需要加载loading的接口
//开发环境
if (process.env.NODE_ENV == 'development') {
  path = ''
}
//生产环境
if (process.env.NODE_ENV == 'production') {
  path = configs.baseurl
}
axios.defaults.timeout = 30000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
//设置拦截器
axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token')
    if (token) config.headers['access-token'] = token
    config.url = path + config.url
    loadingFrequency++
    if (unwantedLoading.indexOf(config.url) == -1) startLoading()
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
//响应拦截器
axios.interceptors.response.use(
  (response) => {
    loadingFrequency--
    if (!loadingFrequency) endLoading()
    return response.data
  },
  (err) => {
    loadingFrequency--
    if (!loadingFrequency) endLoading()
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误'
          break

        case 401:
          err.message = '未授权，请登录'
          break

        case 403:
          err.message = '拒绝访问'
          break

        case 404:
          err.message = '请求地址出错'
          break

        case 408:
          err.message = '请求超时'
          break

        case 500:
          err.message = '服务器内部错误'
          break

        case 501:
          err.message = '服务未实现'
          break

        case 502:
          err.message = '网关错误'
          break

        case 503:
          err.message = '服务不可用'
          break

        case 504:
          err.message = '网关超时'
          break

        case 505:
          err.message = 'HTTP版本不受支持'
          break

        default:
      }
    }
    Message.error(err.message)
    return Promise.reject(err)
  }
)
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
function endLoading() {
  loading.close()
}
//get请求
function get(url, data = {}) {
  let params = {
    params: data
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
//post请求
function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(data))
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
function uploadFile(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default {
  post: post,
  get: get,
  uploadFile: uploadFile
}
