const api = {
    user:{
        login:'/admin/users/login',
        self:'/admin/users/self',
        logout:'/admin/users/logout',
        getAll:'/admin/users/getAll',
        add:'/admin/users/add',
        update:'/admin/users/update',
        del:'/admin/users/del',
        list:'/admin/users/list'
    },
    RolesDep:{
        getAll:'/admin/RolesDep/getAll',
        update:'/admin/RolesDep/update',
        delete:'/admin/RolesDep/delete',
        add:'/admin/RolesDep/add'   
    },
    student:{
        preList:'/admin/student/preList',
        add:'/admin/student/add',
        edit:'/admin/student/edit',
        output:'/admin/student/output',
        importStudent:'/admin/student/importStudent'
    },
    category:{
        list:'/admin/Category/list'
    },
    teacher:{
        list:'/admin/teacher/list',
        add:'/admin/teacher/add',
        info:'/admin/teacher/info'
    },
    government:{
        list:'/admin/government/list',
        add:'/admin/government/add',
        del:'/admin/government/del',
        edit:'/admin/government/edit'
    },
    shipper:{
        list:'/admin/shipper/list',
        add:'/admin/shipper/add'
    },
    area:'/admin/area/list'
};

export default api;
