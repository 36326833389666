<template>
  <div class="layout">
    <div class="layout-menu">
      <div class="layout-menu-title">大数据疫情防控监测系统</div>
      <ul>
        <li
          @click="selectMenu(item, index)"
          :class="[index == activeIndex ? 'active' : '']"
          v-for="(item, index) in routers"
          :key="index"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="view-content">
      <div class="layout-header">
        <el-dropdown class="userName" @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-caret-bottom"></i>
            <span>{{ userInfo.name }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="outLogin">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <img
          :src="userInfo.avatar"
          style="width: 40px; height: 40px; margin-left: 20px"
          alt=""
        />
      </div>
      <div class="router-view">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "Home",
      routers: [
        {
          path: "/home/LsolationPersonnel",
          key: "LsolationPersonnel",
          name: "隔离人员",
        },
        {
          path: "/home/AbnormalTemperature",
          key: "AbnormalTemperature",
          name: "异常体温",
        },
        {
          path: "/home/LsolationPersonnelFeedback",
          key: "LsolationPersonnelFeedback",
          name: "隔离人员反馈",
        },
        {
          path: "/home/LsolationPointMedicalStaff",
          key: "LsolationPointMedicalStaff",
          name: "隔离点工作人员",
        },
        {
          path: "/home/Locationofisolationpoint",
          key: "Locationofisolationpoint",
          name: "隔离点所在位置",
        },
        {
          path: "/home/ReleaseQuarantine",
          key: "ReleaseQuarantine",
          name: "隔离解除",
        },
        {
          path: "/home/TemperatureMeasurement",
          key: "TemperatureMeasurement",
          name: "自定义测温",
        },
        {
          path: "/home/MaterialInventory",
          key: "MaterialInventory",
          name: "物资盘点（待开放）",
        },
        {
          path: "/home/UserAdmin",
          key: "UserAdmin",
          name: "用户管理",
        },
        {
          path: "/home/RoleAdmin",
          key: "RoleAdmin",
          name: "角色管理",
        },
        {
          path: "/home/Equipment",
          key: "Equipment",
          name: "设备进销",
        },
      ],
      activeIndex: 0,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  watch: {
    $route: {
      handler(val) {
        let routers = this.routers;
        routers.map((item, index) => {
          if (item.key == val.name) {
            this.activeIndex = index;
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    handleCommand(val) {
      if (val == "outLogin") {
        this.$store.dispatch("outLogin");
      }
    },
    selectMenu(row, index) {
      if (index != this.activeIndex) {
        this.activeIndex = index;
        this.$router.push(row.path);
      }
    },
  },
};
</script>

<style lang="less">
.layout {
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 260px;
  .layout-menu {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
    background: #1f2e54;
    color: #fff;
    width: 260px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 0px;
    }
    .layout-menu-title {
      font-weight: bold;
      color: #d5e1ff;
      font-size: 20px;
      line-height: 70px;
    }
    ul {
      margin-top: 40px;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        height: 46px;
        padding-left: 27px;
        width: 160px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-weight: 500;
        cursor: pointer;
      }
      .active {
        background: linear-gradient(90deg, #2544ff, #3ba2ff);
        border-radius: 6px;
      }
    }
  }
  .view-content {
    overflow-y: scroll;
    height: 100%;
    .layout-header {
      height: 70px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
    }
    .router-view {
      flex: 1;
      padding: 85px 0 15px 15px;
    }
  }
  .el-dropdown-link {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
    }
    i {
      margin-right: 10px;
    }
  }
}
</style>
