import Vue from "vue";
import Vuex from "vuex";
import api from '../utils/api';
import axios from '../utils/axios';
import { Message, MessageBox } from 'element-ui'
import router from '../router'
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userInfo: {}
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    }
  },
  actions: {
    getUserInfo({ commit }) {
      axios.get(api.user.self).then(res => {
        if (res.code == 0) {
          commit('setUserInfo', res.result.info)
        }
      })
    },
    outLogin() {
      MessageBox.confirm('确认退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.post(api.user.logout).then(res => {
          if (res.code == 0) {
            localStorage.clear()
            router.push('/')
            Message({
              message: '退出登录成功',
              type: 'success'
            })
          } else {
            Message.error(res.msg)
          }
        })
      })
    }
  },
  modules: {
  }
})
